:root {
    --nav-menu-display: none;
}

.active {
    color: var(--clr-sec);
}

nav {
    margin: auto;
    line-height: 2.0;
    background-color: var(--clr-bg-light);
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.2rem var(--clr-bg-dark);
}

nav > ul > li > a:hover {
    text-decoration: none;
}

footer {
    z-index: 1;
    width: 100%;
    background-color: var(--clr-bg-dark);
    position: fixed;
    bottom: 0;
}

@media only screen and (min-width: 1000px) {
    nav {
        width: fit-content;
        padding: 1rem;
    }

    nav > ul {
        list-style: none;
        margin-right: 2rem;
    }

    nav > ul > li {
        display: inline-block;
        margin-left: 1rem;
        border-right: gray solid thin;
        padding-right: 1rem;
    }

    nav > ul > li:last-child {
        border-right: none;
    }

    nav > ul > li > a:is(.active) {
        background-color: var(--clr-bg);
        border-radius: 0.5rem;
    }

    nav > ul > li > a {
        padding: 0.5rem 1rem 0.5rem 1rem;
    }

    .wrapper {
        display: block;
        margin: auto;
        width: max-content;
        max-width: 50rem;
        padding-bottom: 2rem;
    }

    footer {
        padding: 1rem;
    }

    footer > p {
        padding: 0 6rem 0 0;
    }

    #content-wrapper {
        padding-bottom: 2rem;
        display: block;
        overflow: auto;
    }
}

@media only screen and (max-width: 1000px) {
    nav {
        width: 92%;
        padding: 0.5rem;
    }
    nav > ul {
        width: 100%;
        padding: 0;
    }
    nav > ul > li {
        display: var(--nav-menu-display);
    }

    .wrapper {
        display: block;
        width: 92%;
        overflow: hidden;
        padding: 0 4% 0 4%;
    }

    footer {
        padding: 1rem
    }

    footer > p {
        text-align: left;
        padding-right: 2rem;
    }

    #content-wrapper {
        padding-bottom: 5rem;
        display: block;
        overflow: auto;
    }
}
