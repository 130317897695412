.project > .head > h4 {
    padding-top: 0;
}

@media only screen and (min-width: 1000px) {
    .project-container {
        width: 80%;
    }

    .project {
        width: 80%;
        margin: 0 auto 2rem;
        background-color: var(--clr-bg-light);
        padding: 2rem;
        border-radius: 1rem;
        box-shadow: 0.2rem 0.2rem 0.2rem var(--clr-bg-dark);
    }

    .project > picture {
        float: right;
        width: 50%;
        object-fit: contain;
        margin-left: 1rem;
        margin-bottom: 1rem;
        border-radius: 1rem;
    }

    .project > .head {
        background-color: var(--clr-bg-dark);
        border-radius: 1rem;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 1000px) {
    .project-container {
        width: 100%;
    }

    .project {
        width: 90%;
        margin: 0 auto 2rem auto;
        background-color: var(--clr-bg-light);
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: 0.2rem 0.2rem 0.2rem var(--clr-bg-dark);
    }

    .project > picture {
        width: 100%;
        object-fit: contain;
        margin-bottom: 1rem;
        border-radius: 1rem;
    }

    .project > .head {
        background-color: var(--clr-bg-dark);
        border-radius: 1rem;
        margin-bottom: 1rem;
    }

}
