/* latin-ext */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v21/o-0NIpQlx3QUlC5A4PNjXhFVatyB1Wk.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v21/o-0NIpQlx3QUlC5A4PNjXhFVZNyB.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v21/o-0IIpQlx3QUlC5A4PNr6zRAW_0.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/notosans/v21/o-0IIpQlx3QUlC5A4PNr5TRA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
    --clr-bg: rgb(40, 40, 40);
    --clr-bg-dark: rgb(35, 35, 35);
    --clr-bg-light: rgb(50, 50, 50);

    --clr-txt: rgb(255, 255, 255);
    --clr-pri: rgb(255, 140, 140);
    --clr-sec: rgb(170, 170, 255)
}

body {
    display: inline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.5;
    background-color: var(--clr-bg);
    color: var(--clr-txt);
    height: 100%;
    width: 100%;
    max-width: 100%;
}

.mobile {
    display: none;
}

* {
    margin: 0;
}

a {
    text-decoration: none;
    color: var(--clr-pri)
}

a:hover {
    text-decoration: underline;
}

input, button, textarea, select {
    font: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
    text-align: center;
    padding: 1rem;
}

.center {
    text-align: center;
}

.spacing1 {
    margin-top: 42px;
}

.spacing2 {
    padding: 2rem;
}

@media only screen and (min-width: 1000px) {
    .left {
        float: left;
        width: max-content;
    }

    .right {
        float: right;
        width: max-content;
    }

    body {
        padding: 2rem;
    }
}

@media only screen and (max-width: 1000px) {

    .mobile {
        display: block !important;
    }

    .left {
        margin: auto;
        width: max-content;
    }

    .right {
        margin: auto;
        width: max-content;
    }
}
