.imprint {
    width: fit-content;
    margin: auto;
}

.imprint > h1 {
    text-align: left;
}

.imprint > h2 {
    text-align: left;
}

.imprint > h3 {
    text-align: left;
}

.imprint > div {
    padding-left: 1rem;
}
